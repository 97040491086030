import React from "react";
import hospitalLogo from "../../assets/images/hospital-logo.png";
import hospitalOne from "../../assets/images/hospital-one.webp";
import hospitalTwo from "../../assets/images/hospital-two.webp";
import hospitalThree from "../../assets/images/hospital-three.webp";
import "./obeyHospital.css";
import "./obeyHospital.mobile.css";

const ObeyHospital = () => {
  return (
    <div className="obey-hospital-background">
      <div className="container">
        <div className="row">
          <div className="col-sm-2">
            <embed src={hospitalLogo} className="obey-hospital-logo"></embed>
          </div>
          <div className=" col-sm-10 mt-5">
            <p className="obey-hospital-title">
              مستشفى عبيه التخصصي ودار رعاية المسنين
            </p>
            <p className="obey-hospital-body">
              من أجل تحقيق بعض الأهداف، قامت المؤسسة بالتخطيط لإنشاء "مستشفى
              عبيه التخصصي ودار رعاية المسنين" لشمولها <br></br>المجالات
              الأساسية العديدة من الصحية، إلى الاستشفائية والمهنية والتأهيلية
              وغيرها.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <a href="https://wa.me/96178900097?">
              <button className="obey-hospital-button">
                ساهم بإنجاز اهدافنا الإجتماعية
              </button>
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="obey-hospital-images">
              <img src={hospitalOne}></img>
              <img src={hospitalTwo}></img>
              <img src={hospitalThree}></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ObeyHospital;
