import React from "react";
import "./aboutUs.css";
import "./aboutUs.mobile.css";

const AboutUs = () => {
  return (
    <div className="about-us-background">
      <div className="container">
        <div className="row">
          <div className="col center">
            <p className="about-us-title">
              مؤسسة السيد الأميرعبدالله التنوخي (ق) الاجتماعية
            </p>
            <p className="about-us-subtitle ">
              معاً للمشاركة والمساعدة ونشر رسالة أمل وخيرٍ وعطاء
            </p>
            <a href="https://wa.me/96178900097?">
              <button className="about-us-button">تواصل للتعاون معنا</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
