import React from "react";
import "./Copyright.css";
import "./Copyright.mobile.css";

const Copyright = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-sm order-sm-last">
          <p className="copyright-sa">Copyright 2021 SA</p>
        </div>
        <div className="col-sm order-sm-first">
          <p className="copyright-nexus">
            Designed and developed by Nexus Media
          </p>
        </div>
      </div>
    </div>
  );
};

export default Copyright;
