import React from "react";
// import { ScrollingProvider, Section } from "react-scroll-section";
import { ScrollingProvider, Section } from "react-scroll-section";

import Landing from "../../Components/Landing/landing";
import AboutUs from "../../Components/AboutUs/aboutUs";
import AboutUsContent from "../../Components/AboutUsContent/aboutUs-content";
import Goals from "../../Components/Goals/Goals";
import AdminBoard from "../../Components/AdminBoard/AdminBoard";
import Activities from "../../Components/Activities/Activities";
import ObeyHospital from "../../Components/ObeyHospital/ObeyHospital";
import Footer from "../../Components/Footer/Footer";
import Copyright from "../../Components/Copyright/Copyright";

import "./Home.css";
import SocialSolidarity from "../../Components/SocialSolidarity/SocialSolidarity";

const Home = () => {
  return (
    <div>
      <Landing />
      <Section id="about-us">
        <AboutUs />
        <AboutUsContent />
      </Section>
      <Section id="goals">
        <Goals />
      </Section>
      <Section id="admins">
        <AdminBoard />
      </Section>
      <Section id="contact-us">
        <SocialSolidarity />
      </Section>
      <Section id="activities">
        <Activities />
      </Section>
      <Section id="hospital">
        <ObeyHospital />
      </Section>

      <Footer />
      <Copyright />
    </div>
  );
};

export default Home;
