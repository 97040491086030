import React, { useState } from "react";
import logo from "../../assets/images/logo.png";
import burgerMenu from "../../assets/images/burger-menu.svg";
import "./Navbar.css";
import "./Navbar.mobile.css";

import { useScrollSection } from "react-scroll-section";

const Navbar = () => {
  const aboutUsSection = useScrollSection("about-us");
  const goalsSection = useScrollSection("goals");
  const adminSection = useScrollSection("admins");
  const activitiesSection = useScrollSection("activities");
  const hospitalSection = useScrollSection("hospital");
  const contactUsSection = useScrollSection("contact-us");

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [menuTranslate, setMenuTranslate] = useState("translateX(-220px)");
  const [burgerButtonTranslate, setBurgerButtonTranslate] = useState("0");
  const [buttonTranslate, setButtonTranslate] = useState("translateX(0)");

  const handleMenuClick = () => {
    if (!isMenuOpen) {
      setIsMenuOpen(true);
      setMenuTranslate("translateX(0px)");
      setBurgerButtonTranslate("145px");
      setButtonTranslate("translateX(230px)");
    } else if (isMenuOpen) {
      setIsMenuOpen(false);
      setMenuTranslate("translateX(-220px)");
      setBurgerButtonTranslate("0");
      setButtonTranslate("translateX(0px)");
    }
  };

  const desktopNavbar = (
    <div className="desktop-navbar">
      <ul>
        <li>
          <img src={logo} className="logo"></img>
        </li>
        <li
          className="navbar-text navbar-item-about-us"
          onClick={aboutUsSection.onClick}
          selected={aboutUsSection.selected}
        >
          <p>عن المؤسسة</p>
        </li>
        <li
          className="navbar-text"
          onClick={goalsSection.onClick}
          selected={goalsSection.selected}
        >
          <p>أهداف الجمعية</p>
        </li>
        <li
          className="navbar-text"
          onClick={adminSection.onClick}
          selected={adminSection.selected}
        >
          <p>الهيئة الادارية</p>
        </li>
        <li
          className="navbar-text"
          onClick={activitiesSection.onClick}
          selected={activitiesSection.selected}
        >
          <p>أبرز الأنشطة</p>
        </li>
        <li
          className="navbar-text"
          onClick={hospitalSection.onClick}
          selected={hospitalSection.selected}
        >
          <p>المستشفى</p>
        </li>
        <li
          className="navbar-text"
          onClick={contactUsSection.onClick}
          selected={contactUsSection.selected}
        >
          <p>للتواصل</p>
        </li>
        <li className="navbar-button">
          <a href=" https://wa.me/96178900097?">
            <button className="navbar-volunteer-button">
              <p>تطوع معنا</p>
            </button>
          </a>
          <a href=" https://wa.me/96178900097?">
            <button className="navbar-donations-button">
              <p>التبرعات</p>
            </button>
          </a>
        </li>
      </ul>
    </div>
  );

  const mobileNavbarLinks = (
    <div className="navbar-layout" style={{ transform: menuTranslate }}>
      <ul className="navbar-text">
        <li
          className="navbar-text navbar-item-about-us"
          onClick={aboutUsSection.onClick}
          selected={aboutUsSection.selected}
        >
          <p>عن المؤسسة</p>
        </li>
        <li
          className="navbar-text"
          onClick={goalsSection.onClick}
          selected={goalsSection.selected}
        >
          <p>أهداف الجمعية</p>
        </li>
        <li
          className="navbar-text"
          onClick={adminSection.onClick}
          selected={adminSection.selected}
        >
          <p>الهيئة الادارية</p>
        </li>
        <li
          className="navbar-text"
          onClick={activitiesSection.onClick}
          selected={activitiesSection.selected}
        >
          <p>أبرز الأنشطة</p>
        </li>
        <li
          className="navbar-text"
          onClick={contactUsSection.onClick}
          selected={contactUsSection.selected}
        >
          <p>المستشفى</p>
        </li>
        <li
          className="navbar-text"
          onClick={contactUsSection.onClick}
          selected={contactUsSection.selected}
        >
          <p>للتواصل</p>
        </li>
      </ul>
    </div>
  );

  const mobileNavbar = (
    <>
      <div className="mobile-navbar">
        <div className="container">
          <div className="row">
            <ul>
              <li
                className="navbar-button"
                style={{ transform: buttonTranslate }}
              >
                <a href=" https://wa.me/96178900097?">
                  <button className="navbar-volunteer-button">
                    <p>تطوع معنا</p>
                  </button>
                </a>
                <a href=" https://wa.me/96178900097?">
                  <button className="navbar-donations-button">
                    <p>التبرعات</p>
                  </button>
                </a>
              </li>
            </ul>
            <img
              src={burgerMenu}
              className="mobile-navbar-burger-menu"
              onClick={handleMenuClick}
              style={{ left: burgerButtonTranslate }}
            ></img>
          </div>
        </div>
      </div>
      {mobileNavbarLinks}
    </>
  );

  return (
    <>
      {desktopNavbar}
      {mobileNavbar}
    </>
  );
};

export default Navbar;
