import React from "react";
import "./aboutUs-content.css";
import "./aboutUs-content.mobile.css";

const AboutUsContent = () => {
  return (
    <div className="aboutUs-content-background">
      <div className="container">
        <div className="row">
          <div className="col-1 col-sm-2"></div>
          <div className="col-10 col-sm-8 center">
            <p className="about-us-content-text">
              لطالما توارثت الأجيال تعاليم السيد الأميرعبدالله التنوخي (ق) التي
              تحمل معاني العطاء والخير. من <br></br>هذا المنطلق، بُنيت المؤسسة
              في عام 2021 لاستكمال مسيرة الخير المتوارثة. <br></br>إنّ المؤسسة
              تحتضن كلّ مَن هو بحاجة للمساعدة في المجالات كافة، منها الصحية
              والمعيشية والتعليمية وغيرها الكثير.
            </p>
          </div>
          <div className="col-1 col-md-2"></div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsContent;
