import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import Home from "./Pages/Home/Home";
import Navbar from "./Components/Navbar/Navbar";

// Redux
import { Provider } from "react-redux";
import store from "./store";
import { ScrollingProvider } from "react-scroll-section";

function App() {
  return (
    <Provider store={store}>
      <ScrollingProvider>
        <Navbar />
        <Router>
          <Route exact path="/" component={Home} />
        </Router>
      </ScrollingProvider>
    </Provider>
  );
}

export default App;
