import React from "react";
import "./landing.css";
import "./landing.mobile.css";

const Landing = () => {
  return (
    <>
      <div className="landing-background-image">
        <div className="container ">
          <div className="row">
            <div className="col center">
              <p className="landing-title">
                إِنَّ الَّذِينَ آمَنُوا وَعَمِلُوا الصَّالِحَاتِ أُوْلَئِكَ هُمْ
                خَيْرُ الْبَرِيَّةِ
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="landing-color-fade"></div>
    </>
  );
};

export default Landing;
