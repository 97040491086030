import React from "react";
import Admin from "./admin";

import "./adminBoard.css";

const AdminBoard = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col center">
          <p className="admin-board-title">الهيئة الادارية</p>
        </div>
      </div>
      <div className="row">
        <div className="col-2"></div>
        <div className="col-md">
          <Admin name="سماحة الشيخ نعيم حسن" position="رئيساً" />
        </div>
        <div className="col-md">
          <Admin name="الشيخ جهاد زحلان" position="نائباً للرئيس" />
        </div>
        <div className="col-2-md"></div>
      </div>
      <div className="row">
        <div className="col-md">
          <Admin name="الشيخ نزار بو غنام" position="أميناً للسرّ" />
        </div>
        <div className="col-md">
          <Admin name="الشيخ محمود فرج" position="أميناً للصندوق" />
        </div>
        <div className="col-md">
          <Admin name="المهندس رياض القنطار" position="محاسباً" />
        </div>
      </div>

      <div className="row">
        <div className="col-md">
          <Admin name="المحامي توفيق المهتار" position="مسشاراً قانونياً" />
        </div>
        <div className="col-md">
          <Admin name="الشيخ نزيه صعب" position="عضواً" />
        </div>
        <div className="col-md">
          <Admin name="د. نايف ملاعب" position="عضواً" />
        </div>
      </div>
    </div>
  );
};

export default AdminBoard;
