import React from "react";
import GoalsCard from "./goals-card";

import "./goals.css";
import "./goals.mobile.css";

const Goals = () => {
  return (
    <div className="goals-background">
      <div className="container">
        <div className="row">
          <div className="col center">
            <p className="goals-title">أهداف الجمعية</p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-1"></div>
          <div className="col-sm goals-margin">
            <GoalsCard
              pic="goals-one.webp"
              content="إنشاء وإقامة مركز لراحة ورعاية المسنين والعجزة وذوي الاحتياجات الخاصة"
            />
          </div>
          <div className="col-sm goals-margin">
            <GoalsCard
              pic="goals-two.webp"
              content="تأهيل وتدريب ذوي الاحتياجات الخاصة"
            />
          </div>
          <div className="col-sm-1"></div>
        </div>
        <div className="row">
          <div className="col-1"></div>

          <div className="col-sm goals-margin">
            <GoalsCard
              pic="goals-three.webp"
              content="إنشاء وإقامة المؤسسات الصحية والاستشفائية والمهنية ومراكز الرعاية الصحية والاجتماعية ومراكز وحدائق للمسنين وذوي الاحتياجات الخاصة"
            />
          </div>
          <div className="col-sm goals-margin">
            <GoalsCard
              pic="goals-four.webp"
              content="تنظيم ندوات خاصة لشرح مخاطر الآفات الاجتماعية على اختلافها"
            />
          </div>
          <div className="col-1"></div>
        </div>
        <div className="row">
          <div className="col-1"></div>
          <div className="col-sm goals-margin">
            <GoalsCard
              pic="goals-five.webp"
              content="إقامة أيّ نشاط إعلامي أو اجتماعي أو خيري يساهم في تحفيز وتنشيط رعاية المسنين والعجزة وذوي الاحتياجات الخاصة"
            />
          </div>
          <div className="col-sm goals-margin">
            <GoalsCard
              pic="goals-six.webp"
              content="القيام بالأعمال الخيرية (قبول وتقديم الهبات لدور العجزة والمحتاجين والفقراء والأيتام وغيرها)"
            />
          </div>
          <div className="col-1"></div>
        </div>
        <div className="row">
          <div className="col center">
            <a href="https://wa.me/96178900097?">
              <button className="goals-button">ساهم بتحقيق الأهداف</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Goals;
