import React from "react";
import "./goalsCard.css";
import "./goalsCard.mobile.css";

const GoalsCard = ({ pic, content }) => {
  return (
    <div className="row goals-card">
      <div className="col center goals-card-content">
        <p>{content}</p>
      </div>
      <div className="col-5 left">
        <img
          className="goals-card-image"
          src={require(`../../assets/images/${pic}`)}
        ></img>
      </div>
    </div>
  );
};

export default GoalsCard;
