import React from "react";
import logo from "../../assets/images/logo-grey.svg";
import phoneIcon from "../../assets/images/phone-icon.svg";
import mailIcon from "../../assets/images/mail-icon.svg";
import locationIcon from "../../assets/images/location-icon.svg";
import instagram from "../../assets/images/instagram.svg";
import facebook from "../../assets/images/facebook.svg";
import twitter from "../../assets/images/twitter.svg";

import "./Footer.css";
import "./Footer.mobile.css";

const Footer = () => {
  return (
    <div className="footer-background">
      <div className="container">
        <div className="row center">
          <div className="col-sm-3">
            <a href="https://wa.me/96178900097?">
              <button className="footer-button">تواصل معنا</button>
            </a>
          </div>
          <div className="col-sm-6 footer-icons ">
            <img src={locationIcon}></img>

            <p>عبي - قضاء عاليه</p>
            <div className="footer-flex-break"></div>
            <img src={mailIcon}></img>
            <p>info@alsayedalamir.com</p>
            <div className="footer-flex-break"></div>
            <img src={phoneIcon}></img>
            <p>0096178900097</p>
          </div>

          <div className="col-sm-3 footer-logo">
            <embed src={logo}></embed>
          </div>
        </div>
        {/* <div className="row center">
          <div className="footer-social-media">
            <img src={twitter}></img>
            <img src={facebook} className="footer-facebook-icon"></img>
            <img src={instagram}></img>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Footer;
