import React from "react";
import "./activity.css";
import "./activity.mobile.css";

const mobileImages = (
  <div
    id="carouselExampleControls"
    class="carousel slide activity-mobile-images"
    data-ride="carousel"
  >
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img
          class="d-block w-100"
          src={require("../../assets/images/activity-one-one.jpg")}
          alt="First slide"
        ></img>
      </div>
      <div class="carousel-item">
        <img
          class="d-block w-100"
          src={require("../../assets/images/activity-one-two.png")}
          alt="Second slide"
        ></img>
      </div>
      {/* <div class="carousel-item">
        <img
          class="d-block w-100"
          src={require("../../assets/images/activity.png")}
          alt="Third slide"
        ></img>
      </div>
      <div class="carousel-item">
        <img
          class="d-block w-100"
          src={require("../../assets/images/activity.png")}
          alt="Third slide"
        ></img>
      </div> */}
    </div>
    <a
      class="carousel-control-prev"
      href="#carouselExampleControls"
      role="button"
      data-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only"></span>
    </a>
    <a
      class="carousel-control-next"
      href="#carouselExampleControls"
      role="button"
      data-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only"></span>
    </a>
  </div>
);

const dekstopImages = (
  <div className="activity-images">
    <img src={require("../../assets/images/activity-one-one.jpg")}></img>
    <img src={require("../../assets/images/activity-one-two.png")}></img>
    {/* <img src={require("../../assets/images/activity.png")}></img>
    <img src={require("../../assets/images/activity.png")}></img> */}
  </div>
);

const Activity = () => {
  return (
    <div className="">
      <hr className="activity-line"></hr>
      <div className="row">
        <div className="col">
          <p className="activity-title">
            زيارة الموقع الذي ستشاد عليه المؤسسة الصحية في عبيه
          </p>
        </div>
        <div className="col">
          <p className="activity-date">Date: 15/12/2020</p>
        </div>
      </div>

      <p className="activity-body">
        سماحة شيخ العقل الشيخ نعيم حسن، رئيس لجنة الاوقاف الاستاذ حماده حماده،
        مقرر لجنة الاوقاف الشيخ نزار بو غنام، مدير عام المجلس المذهبي الاستاذ
        مازن فياض، مدير الاوقاف الاستاذ نزيه زيعور واعضاء لجنة الاوقاف.
      </p>
      {dekstopImages}
      {mobileImages}
    </div>
  );
};

export default Activity;
