import React from "react";
import Activity from "./activity";
import "./activities.css";
import "./activities.mobile.css";

const Activities = () => {
  return (
    <div className="activities-background">
      <div className="container">
        <div className="row pt-5">
          <div classname="col">
            <p className="activities-title">أنشطة المؤسسة</p>
            <p className="activities-body">
              تقوم المؤسسة بشكل مستمر ودوري بعقد ندوات توعوية ولقاءت وغيرها. كما
              تقوم بتحفيز الأعمال الخيرية وتعزيز روح العطاء.
            </p>
          </div>
        </div>
        <div className="row">
          <Activity />
        </div>
      </div>
    </div>
  );
};

export default Activities;
