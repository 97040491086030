import React from "react";
import "./admin.css";

const Admin = ({ name, position }) => {
  return (
    <div className="admin-content">
      <p className="admin-name">{name}</p>
      <div className="admin-position-content">
        <hr className="admin-line"></hr>
        <p className="admin-position">{position}</p>
      </div>
    </div>
  );
};

export default Admin;
