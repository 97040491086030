import React from "react";
import phoneIcon from "../../assets/images/phone-icon.svg";
import whatsappIcon from "../../assets/images/whatsapp-icon.svg";
import "./socialSolidarityCard.css";
import "./socialSolidarityCard.mobile.css";

const SocialSolidarityCard = ({
  title,
  body,
  number,
  etc,
  buttonClass,
  buttonContent,
  isIcon,
}) => {
  return (
    <div className="social-solidarity-card">
      <p className="social-solidarity-card-title">{title}</p>
      <p className="social-solidarity-card-body">{body}</p>
      {number ? (
        <p className="social-solidarity-card-number">
          {number} <img src={phoneIcon}></img>
        </p>
      ) : (
        ""
      )}
      {etc ? <p className="social-solidarity-card-etc ">{etc}</p> : ""}
      {buttonContent ? (
        <a href="https://wa.me/96178900097?">
          <button className={buttonClass}>
            {isIcon ? (
              <img
                src={whatsappIcon}
                className="social-solidarity-card-button-icon"
              ></img>
            ) : (
              ""
            )}
            {buttonContent}
          </button>
        </a>
      ) : (
        ""
      )}
    </div>
  );
};

export default SocialSolidarityCard;
