import React from "react";
import SocialSolidarityCard from "./SoialSolidarityCard";
import "./socialSolidarity.css";
import "./socialSolidarity.mobile.css";

const SocialSolidarity = () => {
  return (
    <div className="social-solidarity-background">
      <div className="container">
        <div className="row">
          <div className="col center">
            <p className="social-solidarity-title">تضامن إجتماعي </p>
          </div>
        </div>
        <div className="row mt-5 ">
          <div className="col-sm center">
            <SocialSolidarityCard
              title="بوابة التبرعات"
              body="يمكنك التبرع بمبلغ مادي أو مساعدة عينية"
              number="009617800097"
              etc="للتبرعات"
              buttonClass="social-solidarity-card-contact-us-button"
              buttonContent="تواصل مباشرة "
              isIcon={true}
            />
          </div>
          <div
            className="col-sm
           center"
          >
            <SocialSolidarityCard
              title="فرص التطوع متاحة في أي وقت"
              body="يساهم في التأثير بشكل أيجابي إجتماعياً، مشاركتك معنا بعملك التطوعي"
              buttonClass="social-solidarity-card-volunteer-button"
              buttonContent="تطوع معنا"
              isIcon={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialSolidarity;
